import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../Components/Layout/Layout";
import WalletBannerImage from "../assets/images/wallet-exchange.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

const WalletExchange = (props) => {
  const BannerSection = () => {
    return (
      <>
        <section className="page-banner pt-0 lg:pt-20">
          <div className="px-6 pt-10 max-w-6xl mx-auto">
            <div className="block lg:grid grid-cols-12 gap-0  flex-wrap mx-4 items-center">
              <div className=" px-4 flex-1 lg:col-span-7">
                <div className="pages-banner-header-text text-2xl lg:text-4.3xl  ">
                  Wallets & Exchanges
                </div>
                <div className="pages-banner-paragraph-text pb-9 lg:pb-0">
                  Exchanges and Wallets supporting AMY.
                </div>
                <div className="theme_btn mb-5 mt-0 lg:mt-6 ">
                  <div className="block w-full">
                    {" "}
                    How to add AMY Token
                  </div>
                </div>
              </div>
              <div className="flex-1  px-4 lg:col-span-5 ">
                <img
                  src={WalletBannerImage}
                  alt="img"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  const WalletSection = () => {
    return (
      <>
        <section className="ami_exchanges grey_bg">
          <div className="px-6  max-w-6xl mx-auto">
            <div className="tittle pb-4">
              <h2>Wallets</h2>
              <p>
                Your Favourite AMY wallet is now supported by following Crypto
                Wallets.
              </p>
            </div>
            <div className="block lg:grid grid-cols-3 gap-0 -mx-4">
              <div className="mb-4 mt-1 px-4">
                <div className="wallet_item">
                  <div className="media-left">
                    <img
                      alt="img"
                      src="https://storx.tech/assets/images/wallets-exchanges/dcent-wallet.png"
                      className="w-14 h-14 lg:w-auto lg:h-auto"
                    />
                  </div>
                  <div className="media-body">
                    <a
                      href="https://dcentwallet.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="heading"
                    >
                      D'CENT Wallet
                    </a>
                    <p>D'CENT Mobile Wallet - Works on iOS and Android.</p>
                    <div className="mt-1 download-actions grid">
                      <a
                        rel="noreferrer"
                        href="https://apps.apple.com/kr/app/dcent-hardware-wallet/id1447206611"
                        target="_blank"
                        className="btn btn-primary mt-2"
                      >
                        <FontAwesomeIcon icon={faApple} size="lg" /> App Store
                      </a>
                      <a
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.kr.iotrust.dcent.wallet&utm_source=dcentwallet&utm_campaign=mobileapp"
                        target="_blank"
                        className="btn btn-outline-primary mt-2 "
                        //
                      >
                        <FontAwesomeIcon icon={faGooglePlay} size="lg" /> Google
                        Play
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 mt-1 px-4">
                <div className="wallet_item">
                  <div className="media-left">
                    <img
                      alt="img"
                      src="https://storx.tech/assets/images/wallets-exchanges/guarda-wallet.png"
                      className="w-14 h-14 lg:w-auto lg:h-auto"
                    />
                  </div>
                  <div className="media-body">
                    <a
                    rel="noreferrer"
                      href="https://guarda.com/"
                      target="_blank"
                      className="heading"
                    >
                      Guarda Wallet
                    </a>
                    <p>
                      Available on Windows, macOS, Linux, Chrome, iOS and
                      Android.
                    </p>
                    <div className="mt-1 download-actions  grid">
                      <a
                      rel="noreferrer"
                        href="https://guarda.com/web-wallet/"
                        target="_blank"
                        className="btn btn-primary mt-2"
                      >
                        Web Wallet
                      </a>
                      <a
                      rel="noreferrer"
                        href="https://guarda.com/desktop/"
                        target="_blank"
                        className="btn btn-outline-primary mt-2"
                      >
                        Desktop Wallet
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 mt-1 px-4">
                <div className="wallet_item">
                  <div className="media-left">
                    <img
                    alt="img"
                      src="https://storx.tech/assets/images/wallets-exchanges/ellipal-wallet.png"
                      className="w-14 h-14 lg:w-auto lg:h-auto"
                    />
                  </div>
                  <div className="media-body">
                    <a
                    rel="noreferrer"
                      href="https://www.ellipal.com/"
                      target="_blank"
                      className="heading"
                    >
                      Ellipal Wallet
                    </a>
                    <p>
                      Available on secured offline hardware wallet and Android
                      wallet.
                    </p>
                    <div className="mt-1 download-actions grid">
                      <a
                      rel="noreferrer"
                        href="https://www.ellipal.com/pages/coldwallet"
                        target="_blank"
                        className="btn btn-primary mt-2"
                      >
                        Hardware Wallet
                      </a>
                      <a
                      rel="noreferrer"
                        href="https://www.ellipal.com/pages/ellipal-wallet-app"
                        target="_blank"
                        className="btn btn-outline-primary mt-2"
                      >
                        Mobile Wallet
                      </a>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="tittle pt-5 pb-4">
              <h2>Exchanges</h2>
              <p>Trade AMY at Following Exchanges.</p>
            </div>
            <div className="row flex-row">
              <div className="col-lg-12 mb-2 mt-1">
                <div className="exchanges_item text-center">
                  <div className="logo-box">
                    <a
                      href="https://www.bitrue.com/trade/srx_usdt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://storx.tech/assets/images/wallets-exchanges/bitrue.png"
                        className="img-fluid"
                        alt="bitrue.com"
                      />
                    </a>
                  </div>
                  <div className="trading-pairs">
                    <a
                      href="https://www.bitrue.com/trade/srx_usdt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AMY-USDT
                    </a>
                  </div>
                </div>

                <div className="exchanges_item text-center">
                  <div className="logo-box">
                    <a
                    rel="noreferrer"
                      href="https://eu.globiance.com/trade/SRX-XDC"
                      target="_blank"
                    >
                      <img
                        src="https://storx.tech/assets/images/wallets-exchanges/globiance.png"
                        className="img-fluid"
                        alt="eu.globiance.com"
                      />
                    </a>
                  </div>
                  <div className="trading-pairs">
                    <a
                    rel="noreferrer"
                      href="https://eu.globiance.com/trade/SRX-XDC"
                      target="_blank"
                    >
                      AMY-XDC
                    </a>
                  </div>
                </div>

                <div className="exchanges_item text-center">
                  <div className="logo-box">
                    <a href="https://hitbtc.com/srx-to-xdc" target="_blank" rel="noreferrer">
                      <img
                        src="https://storx.tech/assets/images/wallets-exchanges/hitbtc.png"
                        className="img-fluid"
                        alt="hitbtc.com"
                      />
                    </a>
                  </div>
                  <div className="trading-pairs">
                    <a href="https://hitbtc.com/srx-to-xdc" target="_blank" rel="noreferrer">
                      AMY-XDC
                    </a>
                    ,{" "}
                    <a href="https://hitbtc.com/srx-to-usdt" target="_blank" rel="noreferrer">
                      AMY-USDT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <Layout location={props.location}>
        <Helmet>
          {/* <title>{data.page.meta_title || data.page.title}</title> */}
          {/* <meta
            property="og:title"
            content={data.page.meta_title || data.page.title}
          /> */}
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: dark)"
          />
          <meta name="theme-color" content="#144a95" />
          <meta content="noindex" />
          <meta content="nofollow" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          {/* <meta name="description" content={data.page.meta_desc} />
          <meta property="og:description" content={data.page.meta_desc} />
          <meta property="twitter:description" content={data.page.meta_desc} /> */}
        </Helmet>
        <div className="">
          <BannerSection />
          <WalletSection />
        </div>
      </Layout>
    </>
  );
};

export default WalletExchange;
